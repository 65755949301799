import { PDFDocument, PDFArray, PDFField, PDFName, PDFPage } from 'pdf-lib';

import { Coordinates, SignatureBoxInfo } from 'types/parameter';
import { SIGN_TYPES } from './constants';

export const getPositionY = (
  coordItem: Coordinates,
  pageHeight: number,
  pageWidth: number,
  pdfViewWidth: number
): number => {
  return ((pageHeight - coordItem.y - coordItem.height) / pageWidth) * pdfViewWidth;
};

export const generatePDFCoordinates = (pdfData: PDFDocument): SignatureBoxInfo[] => {
  const form = pdfData.getForm();
  const fields = form.getFields();

  const signFields: PDFField[] = [];
  fields.forEach((field: PDFField) => {
    const name = field.getName();
    if (name.includes(SIGN_TYPES.main) || name.includes(SIGN_TYPES.initial)) {
      signFields.push(field);
    }
  });

  const signatureBoxInfos: SignatureBoxInfo[] = [];
  const pages = pdfData.getPages();
  pages.forEach((page: PDFPage, index: number) => {
    const pageAnnotsRaw = page.node.lookupMaybe(PDFName.of('Annots'), PDFArray);
    const pageFieldRefs = pageAnnotsRaw ? pageAnnotsRaw.asArray() : [];

    const pageFieldsCoordinates: Coordinates[] = [];
    signFields.forEach((field: PDFField) => {
      if (pageFieldRefs.includes(field.ref)) {
        const sigWidgets = field.acroField.getWidgets();
        const rect = sigWidgets[0].getRectangle();
        const name = field.getName();

        const coordinate: Coordinates = {
          x: rect.x,
          y: rect.y,
          width: rect.width,
          height: rect.height,
          name: name,
          signed: false,
          type: name.includes(SIGN_TYPES.main)
            ? SIGN_TYPES.main
            : SIGN_TYPES.initial,
        };
        pageFieldsCoordinates.push(coordinate);
      }
    });

    if (pageFieldsCoordinates.length) {
      signatureBoxInfos.push({
        pageIndex: index + 1,
        coordinates: pageFieldsCoordinates,
      });
    }
  });

  return signatureBoxInfos;
};

export const signPDF = (
  signatureInfo: SignatureBoxInfo[],
  selectedPageIndex: number,
  selectedFormIndex: number
): SignatureBoxInfo[] => {
  return [...signatureInfo].map((item) => {
    if (item.pageIndex === selectedPageIndex) {
      let coordinates = [...item.coordinates];
      coordinates[selectedFormIndex].signed = true;
      return {
        pageIndex: selectedPageIndex,
        coordinates,
      };
    } else {
      return item;
    }
  });
};

export const sanitizeText = (dirty: string) => {
  //remove all types of characters that might break the filename
  return dirty != null ? dirty.replace(/[^a-zA-Z0-9]/g, '') : '';
};

export const sanitizedPDFFileName = (name: string, date: string) => {
  const sanitizeName = name
    .trim()
    .split(' ')
    .map((item) => sanitizeText(item))
    .join('-');
  return (sanitizeName + '-' + date + '.pdf').toLowerCase();
};
