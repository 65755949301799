import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';

import { Button } from 'components/Button';
import { usePDFContex } from 'hooks/usePDFContex';
import { getValueFromSession, removeValueFromSession } from 'utills/storage';
import Logo from 'components/Logo/Logos';
import { CloseConfirmationModal } from 'pages/DocumentReview/components/CloseConfirmationModal';
import useTheme from 'hooks/useTheme';

import styles from './Home.module.scss';

export default function HomePage() {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [pdfLoad, setPdfLoad] = useState(false);
  const [isCloseModal, setIsCloseModal] = useState<boolean>(false);
  const serviceOrder = getValueFromSession('serviceOrder');
  const { pdf } = usePDFContex();
  const pdfMetaData: any = getValueFromSession('pdfMetaData') || {};
  const link = getValueFromSession('link') || '';

  useEffect(() => {
    removeValueFromSession('signature');
  }, []);

  useEffect(() => {
    if (pdfMetaData['x-ms-meta-signstatus'] !== 'UNSIGNED') {
      navigate(`/congratulation?${link}`);
    }
  }, [pdfMetaData]);

  const handleStart = () => {
    navigate(`/document?${link}`);
  };

  const handleDocumentLoadSuccess = () => {
    setPdfLoad(true);
  };

  if (!serviceOrder || !pdf) {
    return null;
  }

  return (
    <>
      <Logo className={styles.logo} />
      <div className={styles.mainContent}>
        <div>
          <h2 className={styles.title}>My Comfort Design</h2>
          <p className={styles.description}>Your documents are ready to sign!</p>
        </div>
        <div className={styles.pdf}>
          <div style={{ top: '24px', left: '16px' }} />
          <div style={{ top: '32px', left: '8px' }} />
          <Document file={pdf} onLoadSuccess={handleDocumentLoadSuccess}>
            {pdfLoad && <Page width={198} pageNumber={1} />}
          </Document>
        </div>
        {!isCloseModal && (
          <div className={styles.footer}>
            <div className={styles.actions}>
              <Button onClick={handleStart}>Get Started</Button>
            </div>
          </div>
        )}
      </div>
      <CloseConfirmationModal
        isModalOpen={isCloseModal}
        closeModal={() => setIsCloseModal(false)}
      />
    </>
  );
}
