import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Button } from 'components/Button';
import { usePDFContex } from 'hooks/usePDFContex';
import { removeValueFromSession } from 'utills/storage';

import { CloseConfirmationModal } from './components/CloseConfirmationModal';
import { PDFReader } from './components/PDFReader';
import styles from './DocumentReview.module.scss';
import { PDFAlert } from 'components/PDFAlert';
interface ChildRef {
  finishPDF: () => void; //Interface to define the finishPDF metaform to be referenced with the handleFinish method of the PDFReader component.
}

export default function DocumentReview() {
  const navigate = useNavigate();
  const [isCloseModal, setIsCloseModal] = useState<boolean>(false);
  const [unsignedBoxes, setUnsignedBoxes] = useState<string[]>([]);
  const { pdf } = usePDFContex();
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const refPDF = useRef<ChildRef>(null); //Reference for the PDFReader component

  useEffect(() => {
    return () => {
      removeValueFromSession('signature');
    };
  }, []);

  const handleNext = () => {
    if (unsignedBoxes.length) {
      setIsAlert(true);
      document.getElementById(unsignedBoxes[0])?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    } else {
      refPDF.current?.finishPDF(); //Extra validation for when all signatures have been signed, and then execute the handleFinish function of the PDFReader component.
    }
  };

  if (!pdf) {
    navigate('/');
  }

  return (
    <div className={styles.document}>
      <div className={styles.title}>
        <div className={styles.titleContent}>
          <CloseIcon
            width={20}
            cursor="pointer"
            onClick={() => setIsCloseModal(true)}
          />
          <span>Your Documents</span>
          <Button variant="text" onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
      <div className={styles.docContent}>
        <PDFReader
          ref={refPDF} //Reference for the PDFReader component
          uncompletedSignature={isCloseModal}
          file={pdf}
          unsignedBoxes={unsignedBoxes}
          setUnsignedBoxes={setUnsignedBoxes}
          alertNext={isAlert}
        />
      </div>
      <CloseConfirmationModal
        isModalOpen={isCloseModal}
        closeModal={() => setIsCloseModal(false)}
      />
    </div>
  );
}
