import {
  useEffect,
  useState,
  useCallback,
  PropsWithChildren,
  ReactElement,
} from 'react';
import axios from 'axios';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ToastProvider, AppearanceTypes } from 'react-toast-notifications';

import HomePage from 'pages/Home';
import DocumentReview from 'pages/DocumentReview';
import CongratulationPage from 'pages/Congratulation';
import { Layout } from 'components/Layout';
import { Spinner } from 'components/Spinner';
import { Toast } from 'components/Toast';
import { ViewportProvider } from 'hooks/useViewport';
import { PDFContextProvider } from 'hooks/usePDFContex';
import { ThemeProvider } from 'hooks/useTheme';
import {
  getValueFromSession,
  setValueInSession,
  removeValueFromSession,
} from 'utills/storage';
import { azurePDFAuth } from 'utills/azureRequestScript';
import { Buffer } from 'buffer';
import fetchData from 'utills/cloudSyncApi/decryptData';
import Modal from 'react-modal';
import styles from '../components/PDFAlert/PDFAlert.module.scss';
import { ReactComponent as AttentionIcon } from 'assets/icons/attentionmark.svg';
interface ToastProps {
  appearance: AppearanceTypes;
}

const MyCustomToast = ({
  appearance,
  children,
}: PropsWithChildren<ToastProps>): ReactElement => (
  <Toast isError={appearance === 'error'}>{children}</Toast>
);

export default function Routers() {
  const [pdf, setPdf] = useState<any>(null);
  const [fetching, setFetching] = useState(false);
  const [graphAPIToken, setGraphAPIToken] = useState<string>('');
  const params = new URLSearchParams(window.location.search);
  const search = window.location;
  const [errorDecrypt, setErrorDecrypt] = useState<string>('');

  const fetchPDF = useCallback(async (id, token) => {
    setPdf(null);
    const pdfMetaData: any = getValueFromSession('pdfMetaData') || {};
    setFetching(true);
    const headers = {
      'x-ms-date': '',
      Authorization: '',
      'x-ms-version': '2021-04-10',
    };
    const signedPath = `/${process.env.REACT_APP_AZURE_SIGNED_SO_CONTAINER}/signed-service-order-${id}.pdf`;
    const signedAuths = azurePDFAuth(headers, 'GET', signedPath);

    await fetch(
      `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${signedPath}`,
      {
        headers: {
          ...headers,
          'x-ms-date': signedAuths.headerDate,
          Authorization: signedAuths.headerAuthorization,
        },
      }
    )
      .then(async (res) => {
        if (res.status >= 400) {
          throw res.status;
        } else {
          const metaData: any = {};
          const reponseHeaders = Object.fromEntries(res.headers);
          for (const header in reponseHeaders) {
            if (header.includes('x-ms-meta')) {
              metaData[header] = reponseHeaders[header];
            }
            if (header === 'x-ms-meta-signaturescoords') {
              setValueInSession('signatures', reponseHeaders[header]);
            }
            if (header === 'x-ms-meta-branchphone') {
              setValueInSession('branchPhoneNumber', reponseHeaders[header]);
            }
            if (header === 'x-ms-meta-branchid') {
              setValueInSession('branchId', reponseHeaders[header]);
            }
            if (header === 'x-ms-meta-clientname') {
              setValueInSession('clientname', reponseHeaders[header]);
            }
            if (header === 'x-ms-meta-signedpdfname') {
              setValueInSession('signedpdfname', reponseHeaders[header]);
            }
          }
          if (
            !pdfMetaData['x-ms-meta-clientemail'] ||
            pdfMetaData['x-ms-meta-clientemail'] !==
              metaData['x-ms-meta-clientemail']
          ) {
            removeValueFromSession('authToken');
          }
          setValueInSession('pdfMetaData', metaData);
          return res.arrayBuffer();
        }
      })
      .then((data) => {
        setPdf(data);
        setFetching(false);
      })
      .catch(async (err) => {
        if (err === 404) {
          const unsignedPath = `/${process.env.REACT_APP_AZURE_UNSIGNED_SO_CONTAINER}/unsigned_service_order_${id}.pdf`;
          const unsignedAuths = azurePDFAuth(headers, 'GET', unsignedPath);

          await fetch(
            `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${unsignedPath}`,
            {
              headers: {
                ...headers,
                'x-ms-date': unsignedAuths.headerDate,
                Authorization: unsignedAuths.headerAuthorization,
              },
            }
          )
            .then(async (res) => {
              if (res.status >= 400) {
                throw false;
              } else {
                const metaData: any = {};
                const reponseHeaders = Object.fromEntries(res.headers.entries());
                console.log('responseheaders', reponseHeaders);
                for (const header in reponseHeaders) {
                  if (header.includes('x-ms-meta')) {
                    metaData[header] = reponseHeaders[header];
                  }
                  if (header === 'x-ms-meta-signaturescoords') {
                    setValueInSession('signatures', reponseHeaders[header]);
                  }
                  if (header === 'x-ms-meta-branchphone') {
                    setValueInSession('branchPhoneNumber', reponseHeaders[header]);
                  }
                  if (header === 'x-ms-meta-branchid') {
                    setValueInSession('branchId', reponseHeaders[header]);
                  }
                  if (header === 'x-ms-meta-clientname') {
                    setValueInSession('clientname', reponseHeaders[header]);
                  }
                }
                if (
                  !pdfMetaData['x-ms-meta-clientemail'] ||
                  pdfMetaData['x-ms-meta-clientemail'] !==
                    metaData['x-ms-meta-clientemail']
                ) {
                  removeValueFromSession('authToken');
                }
                setValueInSession('pdfMetaData', metaData);

                return res.arrayBuffer();
              }
            })
            .then((data) => {
              setPdf(data);
            })
            .finally(() => {
              console.clear();
              setFetching(false);
            });
        } else {
          console.clear();
          setFetching(false);
        }
      })
      .finally(() => {
        console.clear();
      });
  }, []);

  async function saveDataDecrypt() {
    const base64Data = params.get('data');
    if (base64Data) {
      const decodedData = JSON.parse(Buffer.from(base64Data, 'base64').toString());
      const dataDecrypt = await fetchData(decodedData);

      if (dataDecrypt.error) {
        setErrorDecrypt(dataDecrypt.error);
        return;
      }
      if (!fetching && !pdf) {
        fetchPDF(dataDecrypt.serviceOrderID, graphAPIToken);
        setValueInSession('serviceOrder', dataDecrypt.serviceOrderID);
        setValueInSession('link', search.search.split('?')[1]);
      }
    }
  }
  useEffect(() => {
    if (graphAPIToken && !fetching) {
      saveDataDecrypt();
    }
  }, [graphAPIToken, params]);
  useEffect(() => {
    const body = {
      client_id: process.env.REACT_APP_AZURE_CLIENT_ID || '',
      scope: 'https://graph.microsoft.com/.default',
      client_secret: process.env.REACT_APP_AZURE_CLIENT_SECRET || '',
      grant_type: 'client_credentials',
    };
    axios
      .post(process.env.REACT_APP_AZURE_GRAPH_TOKEN_API + '', body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setGraphAPIToken(res.data.access_token);
        setValueInSession('graphAPIToken', res.data.access_token);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!pdf || fetching) {
    return (
      <BrowserRouter>
        <ViewportProvider>
          <ThemeProvider>
            <Layout secondary>
              {errorDecrypt != '' && (
                <div>
                  <Modal
                    isOpen={true}
                    className={styles.content}
                    overlayClassName={styles.overlay}
                  >
                    <div className={styles.description}>
                      <AttentionIcon width={20} />
                      <span>{errorDecrypt}</span>
                    </div>
                  </Modal>
                </div>
              )}
              {window?.location?.pathname !== '/' && (
                <Spinner color="#fff" width={64} height={64} />
              )}
            </Layout>
          </ThemeProvider>
        </ViewportProvider>
      </BrowserRouter>
    );
  }

  return (
    <ToastProvider components={{ Toast: MyCustomToast }}>
      <BrowserRouter>
        <ViewportProvider>
          <ThemeProvider>
            {pdf && (
              <PDFContextProvider defaulPDF={pdf}>
                <Layout>
                  <Routes>
                    <Route path="/" element={<></>} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/document" element={<DocumentReview />} />
                    <Route path="/congratulation" element={<CongratulationPage />} />

                    <Route path="*" element={<Navigate replace to="/home" />} />
                  </Routes>
                </Layout>
              </PDFContextProvider>
            )}
          </ThemeProvider>
        </ViewportProvider>
      </BrowserRouter>
    </ToastProvider>
  );
}
