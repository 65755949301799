import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';

import Styled from 'types/Styled';

import styles from './Divider.module.scss';

export const Divider = ({ className }: PropsWithChildren<Styled>): ReactElement => (
  <div className={classNames(styles.container, className)} />
);
