import { PropsWithChildren, ReactElement } from 'react';
import Styled from 'types/Styled';

import styles from './SignBadge.module.scss';

interface SingBadgeProps extends Styled {
  id?: string;
  title: string;
  fontStyle?: any;
  variant?: 'primary' | 'secondary';
  onClick(): void;
}

export const SignBadge = (
  props: PropsWithChildren<SingBadgeProps>
): ReactElement => {
  const { onClick, style, title, id, fontStyle } = props;

  return (
    <div id={id} style={style} onClick={onClick} className={styles.container}>
      <span style={fontStyle}>{title}</span>
    </div>
  );
};
