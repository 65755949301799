import { PropsWithChildren, ReactElement, useRef, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import classNames from 'classnames';

import Styled from 'types/Styled';
import { Button } from 'components/Button';
import { Divider } from 'components/Divider';
import { useViewport } from 'hooks/useViewport';

import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import { ReactComponent as LightOnIcon } from 'assets/icons/lightOn.svg';

import styles from './SignaturePad.module.scss';

interface SignaturePadProps extends Styled {
  title: string;
  note: string;
  isLarge?: boolean;
  handleSign(url: string): void;
}

export const SignaturePad = ({
  title,
  note,
  isLarge = false,
  handleSign,
  className,
}: PropsWithChildren<SignaturePadProps>): ReactElement => {
  const signRef = useRef<SignatureCanvas>(null);
  const { width, height } = useViewport();
  const [disableCapture, setDisableCapture] = useState<boolean>(true);
  const [renderCanvas, setRenderCanvas] = useState(true);
  const handleCapture = () => {
    const signUrl = signRef.current?.toDataURL('image/png');
    signUrl && handleSign && handleSign(signUrl);
  };

  const handleClearPad = () => {
    setDisableCapture(true);
    signRef.current?.clear();
  };

  // This timeout is needed to fix the issue happeing only on chrome mobile, with the canvas not resizing properly after rotating.
  useEffect(() => {
    window.onresize = function () {
      setRenderCanvas(false);
      setTimeout(() => {
        setRenderCanvas(true);
      }, 100);
    };
  }, []);

  useEffect(() => {
    handleClearPad();
  }, [title]);

  const isMobile = width < 576 || height > 450;

  useEffect(() => {
    handleClearPad();
  }, [width]);

  const calculateHeight = () => {
    return height > 450
      ? isLarge
        ? 275
        : 220
      : height - 155 > 220
      ? 220
      : height - 155;
  };

  return (
    <div
      className={classNames(
        isMobile ? styles.mobileContainer : styles.container,
        className
      )}
    >
      {height > 450 && (
        <>
          {width < 576 ? (
            <div className={styles.mobileNote}>
              <LightOnIcon />
              <span>Rotate your device for a better experience</span>
            </div>
          ) : (
            <span className={styles.note} style={{ userSelect: 'none' }}>
              {note}
            </span>
          )}
        </>
      )}
      {renderCanvas && (
        <SignatureCanvas
          penColor="black"
          canvasProps={{
            style: {
              height: calculateHeight(),
            },
            className: 'largeSignaturePad',
          }}
          onBegin={() => setDisableCapture(false)}
          ref={signRef}
          clearOnResize={true}
        />
      )}

      <Divider />
      <div className={isMobile ? styles.mobileActions : styles.actions}>
        <Button onClick={handleCapture} disabled={disableCapture}>
          {title}
        </Button>
        <div className={styles.clear} onClick={handleClearPad}>
          <ResetIcon fontSize={24} />
          <span>Clear</span>
        </div>
      </div>
    </div>
  );
};
