import { ReactComponent as DefaultLogo } from 'assets/icons/helpCenter.svg';
import { ReactComponent as DefaultWhiteLogo } from 'assets/icons/helpCenterWhite.svg';
import { ReactComponent as HelpCenterLogo579 } from 'assets/icons/helpCenter_579.svg';
import { ReactComponent as HelpCenterWhiteLogo579 } from 'assets/icons/helpCenterWhite_579.svg';
import { ReactComponent as HelpCenterLogo1161 } from 'assets/icons/helpCenter_1161.svg';
import { ReactComponent as HelpCenterLogoWhite1161 } from 'assets/icons/helpCenterWhite_1161.svg';
import { getValueFromSession } from 'utills/storage';

type Props = {
  className: any;
  isSecondary?: boolean;
};

const HelpCenterLogo = ({ className, isSecondary = false }: Props) => {
  const branchId = getValueFromSession('branchId');

  switch (branchId) {
    case '579':
      return isSecondary ? (
        <HelpCenterWhiteLogo579 className={className} />
      ) : (
        <HelpCenterLogo579 className={className} />
      );
    case '1161':
      return isSecondary ? (
        <HelpCenterLogoWhite1161 className={className} />
      ) : (
        <HelpCenterLogo1161 className={className} />
      );
    default:
      return isSecondary ? (
        <DefaultWhiteLogo className={className} />
      ) : (
        <DefaultLogo className={className} />
      );
  }
};

export default HelpCenterLogo;
