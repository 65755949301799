import { ReactComponent as DefaultLogo } from 'assets/logo.svg';
import { ReactComponent as Logo438 } from 'assets/logo_438.svg';
import { ReactComponent as Logo579 } from 'assets/logo_579.svg';
import { ReactComponent as Logo89 } from 'assets/logo_89.svg';
import { ReactComponent as Logo90 } from 'assets/logo_90.svg';
import { ReactComponent as Logo803 } from 'assets/logo_803.svg';
import { ReactComponent as Logo804 } from 'assets/logo_804.svg';
import { ReactComponent as Logo1161 } from 'assets/logo_1161.svg';
import { ReactComponent as Logo29 } from 'assets/logo_29.svg';
import { ReactComponent as Logo685 } from 'assets/logo_685.svg';
import { ReactComponent as Logo1219 } from 'assets/logo_1219.svg';
import { ReactComponent as Logo695 } from 'assets/logo_695.svg';
import { ReactComponent as Logo1363 } from 'assets/logo_1363.svg';
import { ReactComponent as Logo1743 } from 'assets/logo_1743.svg';
import { ReactComponent as Logo1341 } from 'assets/logo_1341.svg';
import { ReactComponent as Logo1487 } from 'assets/logo_1487.svg';
import { ReactComponent as Logo1713 } from 'assets/logo_1713.svg';
import { ReactComponent as Logo84 } from 'assets/logo_84.svg';
import { ReactComponent as Logo1712 } from 'assets/logo_1712.svg';
import { ReactComponent as Logo1271 } from 'assets/logo_1271.svg';
import { ReactComponent as Logo702 } from 'assets/logo_702.svg';
import { ReactComponent as Logo1445 } from 'assets/logo_1445.svg';
import { ReactComponent as Logo600 } from 'assets/logo_600.svg';
import { ReactComponent as Logo669 } from 'assets/logo_669.svg';
import { ReactComponent as Logo1540 } from 'assets/logo_1540.svg';
import { ReactComponent as Logo691 } from 'assets/logo_691.svg';
import { ReactComponent as Logo673 } from 'assets/logo_673.svg';
import { getValueFromSession } from 'utills/storage';

type Props = {
  className: any;
};

const Logo = ({ className }: Props) => {
  const branchId = getValueFromSession('branchId');

  switch (branchId) {
    case '438':
      return <Logo438 className={className} />;
    case '579':
      return <Logo579 className={className} />;
    case '1161':
      return <Logo1161 className={className} />;
    case '29':
      return <Logo29 className={className} />;
    case '685':
      return <Logo685 className={className} />;
    case '89':
      return <Logo89 className={className} />;
    case '90':
      return <Logo90 className={className} />;
    case '803':
      return <Logo803 className={className} />;
    case '804':
      return <Logo804 className={className} />;
    case '1219':
      return <Logo1219 className={className} />;
    case '695':
      return <Logo695 className={className} />;
    case '1363':
      return <Logo1363 className={className} />;
    case '1743':
      return <Logo1743 className={className} />;
    case '1341':
      return <Logo1341 className={className} />;
    case '1487':
      return <Logo1487 className={className} />;
    case '1713':
      return <Logo1713 className={className} />;
    case '84':
      return <Logo84 className={className} />;
    case '1712':
      return <Logo1712 className={className} />;
    case '1271':
      return <Logo1271 className={className} />;
    case '702':
      return <Logo702 className={className} />;
    case '1445':
      return <Logo1445 className={className} />;
    case '691':
      return <Logo691 className={className} />;
    case '600':
      return <Logo600 className={className} />;
    case '669':
      return <Logo669 className={className} />;
    case '1540':
      return <Logo1540 className={className} />;
    case '673':
      return <Logo673 className={className} />;
    default:
      return <DefaultLogo className={className} />;
  }
};

export default Logo;
