import { PropsWithChildren, ReactElement } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import { useViewport } from 'hooks/useViewport';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import Styled from 'types/Styled';

import styles from './Modal.module.scss';

interface EnhancedModalProps extends Styled {
  isModalOpen: boolean;
  title: string;
  isLarge?: boolean;
  closeModal(): void;
}

Modal.setAppElement('#root');

export const EnhancedModal = ({
  isModalOpen,
  closeModal,
  children,
  title = '',
  isLarge = false,
  className,
}: PropsWithChildren<EnhancedModalProps>): ReactElement => {
  const { width, height } = useViewport();

  const isMobile = width < 576 || height < 450;

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      className={classNames(
        isMobile
          ? styles.mobileContent
          : isLarge
          ? styles.largeContent
          : styles.content,
        className
      )}
      overlayClassName={styles.overlay}
      contentLabel="Modal"
    >
      <div className={styles.header} style={{ userSelect: 'none' }} >
        {title}
        <CloseIcon
          width={22}
          cursor="pointer"
          className={styles.closeIcon}
          onClick={closeModal}
        />
      </div>
      {children}
    </Modal>
  );
};
