import { PropsWithChildren, ReactElement } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import Styled from 'types/Styled';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as AttentionIcon } from 'assets/icons/attentionmark.svg';

import styles from './Toast.module.scss';

interface ToastProps extends Styled {
  isError: boolean;
}

Modal.setAppElement('#root');

export const Toast = ({
  isError,
  children,
}: PropsWithChildren<ToastProps>): ReactElement => (
  <div className={classNames(styles.content, !isError && styles.successContent)}>
    <div className={styles.description}>
      {isError ? <AttentionIcon width={20} /> : <CheckIcon width={20} />}
      <span>{children}</span>
    </div>
  </div>
);
