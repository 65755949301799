import {
  ColorTypes,
  DefaultColors,
  AtlasColors,
  UniqueColors,
  FloridaColors,
  BrothersColors,
  RSAndrewsColors,
  AllGoodColors,
  AndysColors,
  ConwayColors,
  WillFixColors,
  BlueDotColors,
  AjPerriColors,
  AirExpertColors,
  EliteAirColors,
  McCarthyColors,
  TotalComfortColors,
  AksarbenColors,
  ColumbusWorthingtonAirColors,
  HauserColors,
  ComfortLexingtonColors,
  BobHamiltonColors,
  RogerColors,
  YesColors,
  GreenstarColors,
  RightTimeColors,
  BeutlerColors,
  BlueflameColors,
} from './colors';

export type ThemeType = {
  colors: ColorTypes;
};

export interface BasicStyleProps {
  theme: ThemeType;
}

const THEMES = {
  default: {
    colors: DefaultColors,
  },
  atlas: {
    colors: AtlasColors,
  },
  unique: {
    colors: UniqueColors,
  },
  florida: {
    colors: FloridaColors,
  },
  brothers: {
    colors: BrothersColors,
  },
  rsandrews: {
    colors: RSAndrewsColors,
  },
  allGood: {
    colors: AllGoodColors,
  },
  andys: {
    colors: AndysColors,
  },
  conway: {
    colors: ConwayColors,
  },
  willFix: {
    colors: WillFixColors,
  },
  blueDot: {
    colors: BlueDotColors,
  },
  ajPerri: {
    colors: AjPerriColors,
  },
  airExperts: {
    colors: AirExpertColors,
  },
  eliteAir: {
    colors: EliteAirColors,
  },
  mcCarthy: {
    colors: McCarthyColors,
  },
  totalComfort: {
    colors: TotalComfortColors,
  },
  aksarben: {
    colors: AksarbenColors,
  },
  columbusWorthingtonAir: {
    colors: ColumbusWorthingtonAirColors,
  },
  hauser: {
    colors: HauserColors,
  },
  comfortLexington: {
    colors: ComfortLexingtonColors,
  },
  bobHamilton: {
    colors: BobHamiltonColors,
  },
  roger: {
    colors: RogerColors,
  },
  yes: {
    colors: YesColors,
  },
  greenstar: {
    colors: GreenstarColors,
  },
  rightTime: {
    colors: RightTimeColors,
  },
  beutler: {
    colors: BeutlerColors,
  },
  blueflame: {
    colors: BlueflameColors,
  },
};

export default THEMES;
