import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

// Set Date header value for authorization
// Should be UTC GMT string

const azureStorageAccount:string = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT || '';
const azureStorageKey:string = process.env.REACT_APP_AZURE_STORAGE_KEY || '';

export const azurePDFAuth = (headers: any, method: string, path: string) => {
  const headerDate = new Date().toUTCString();
  const signatureParts = [
    method.toUpperCase(),
    headers['content-encoding'] || '',
    headers['content-language'] || '',
    headers['content-length'] || '',
    headers['content-md5'] || '',
    headers['content-type'] || '',
    headers['x-ms-date'] || '',
    headers['if-modified-since'] || '',
    headers['if-match'] || '',
    headers['if-none-match'] || '',
    headers['if-unmodified-since'] || '',
    headers['range'] || '',
  ];

  // Construct CanonicalizedHeaders
  const canonicalHeaderNames: any = [];
  Object.keys(headers).forEach((key) => {
    if (key.startsWith('x-ms-')) {
      canonicalHeaderNames.push(key);
    }
  });

  // Sort headers lexographically by name
  canonicalHeaderNames.sort();

  const canonicalHeaderParts: any = [];
  canonicalHeaderNames.forEach((key: any) => {
    let value = headers[key];

    // Replace whitespace in value but not if its within quotes
    if (!value.startsWith('"')) {
      value = value.replace(/\s+/, ' ');
    }

    if (key === 'x-ms-date') {
      value = headerDate;
    }

    canonicalHeaderParts.push(`${key}:${value}`);
  });

  // Add headers to signature
  signatureParts.push.apply(signatureParts, canonicalHeaderParts);

  // Construct CanonicalizedResource
  const canonicalResourceParts = [`/${azureStorageAccount}${path}`];
  // const canonicalQueryNames:any = [];
  // canonicalQueryNames.sort();
  // canonicalQueryNames.forEach((queryName) => {
  //   const value = pm.request.url.query.get(queryName);

  //   // NOTE: This does not properly explode multiple same query params' values
  //   // and turn them into comma-separated list
  //   canonicalResourceParts.push(`${queryName}:${value}`);
  // });

  // Add resource to signature
  signatureParts.push.apply(signatureParts, canonicalResourceParts);

  // Now, construct signature raw string
  const signatureRaw = signatureParts.join('\n');

  // Hash it using HMAC-SHA256 and then encode using base64
  const storageKey = azureStorageKey;
  const signatureBytes = hmacSHA256(signatureRaw, Base64.parse(storageKey));
  const signatureEncoded = signatureBytes.toString(Base64);

  const headerAuthorization = `SharedKey ${azureStorageAccount}:${signatureEncoded}`;

  return {
    headerDate,
    headerAuthorization,
  };
};
