import { ReactElement } from 'react';
import ReactLoading from 'react-loading';
import classNames from 'classnames';

import Styled from 'types/Styled';

import styles from './Spinner.module.scss';

interface SpinnerProps extends Styled {
  width?: number;
  height?: number;
  color?: string;
}

export function Spinner({
  width = 48,
  height = 48,
  color = '#3E91DE',
  className,
}: SpinnerProps): ReactElement {
  return (
    <div className={classNames(styles.container, className)}>
      <ReactLoading
        width={width}
        height={height}
        className=""
        type="spinningBubbles"
        color={color}
      />
    </div>
  );
}
