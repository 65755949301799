import { PropsWithChildren, ReactElement, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import useTheme from 'hooks/useTheme';
import { useViewport } from 'hooks/useViewport';

import { BasicStyleProps } from 'types/theme';
import styles from './Layout.module.scss';

const useStyles = makeStyles({
  container: ({ theme }: BasicStyleProps) => ({
    width: '100vw',
    background: `linear-gradient(to right, ${theme.colors.GRADIENT_SECONDARY}, ${theme.colors.GRADIENT_PRIMARY} 50%)`,
    touchAction: 'pan-x pan-y',
  }),
});

export function Layout({
  secondary = false,
  children,
}: PropsWithChildren<{ secondary?: boolean }>): ReactElement {
  const { theme } = useTheme();
  const classes = useStyles({ theme });
  const { pathname } = useLocation();
  const { height } = useViewport();
  const LoadingMessage = () => <div>Loading...</div>;

  return (
    <div
      className={classes.container}
      style={{
        height,
        minHeight: pathname === '/document' || secondary ? '100%' : '680px',
      }}
    >
      <Suspense fallback={<LoadingMessage />}>
        <div className={styles.content}>{children}</div>
      </Suspense>
    </div>
  );
}
