import { PropsWithChildren, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import Styled from 'types/Styled';

import useTheme from 'hooks/useTheme';
import { Button } from 'components/Button';
import { getValueFromSession, setSignatureInSession } from 'utills/storage';
import Logo from 'components/Logo/Logos';
import HelpCenterLogo from 'components/HelpCenterLogo/HelpCenterLogo';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './CloseConfirmationModal.module.scss';

interface ButtonProps extends Styled {
  isModalOpen: boolean;
  closeModal(): void;
}

Modal.setAppElement('#root');

export const CloseConfirmationModal = ({
  isModalOpen,
  closeModal,
}: PropsWithChildren<ButtonProps>): ReactElement => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const link = getValueFromSession('link') || '';
  const handleClosePDF = () => {
    navigate(`/home?${link}`);
  };

  const branchPhoneNumber = getValueFromSession('branchPhoneNumber');
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnEsc={false}
      className={styles.modalContent}
      overlayClassName={styles.overlay}
      contentLabel="Modal"
    >
      <div className={styles.container}>
        <CloseIcon
          width={20}
          cursor="pointer"
          className={styles.closeIcon}
          onClick={closeModal}
        />
        <Logo className={styles.logo} />
        <p className={styles.title}>Are you sure you want to close?</p>
        <p className={styles.description}>
          Your document will be waiting for you, but you will need to resign.
        </p>
        <div className={styles.actions}>
          <Button onClick={closeModal}>NEVERMIND</Button>
          <Button variant="secondary" onClick={handleClosePDF}>
            EXIT
          </Button>
        </div>
        <HelpCenterLogo className={styles.help} isSecondary />
        <p className={styles.description}>
          <b>Questions?</b> We’re here to help!
        </p>
        <p className={styles.tel} style={{ color: theme.colors.SECONDARY }}>
          {branchPhoneNumber || ''}
        </p>
      </div>
    </Modal>
  );
};
