import Signature from 'types/Signature';

export const getSignatureFromSession = (): Signature => {
  const signature = localStorage.getItem('signature');
  try {
    if (signature) return JSON.parse(signature);
    return {};
  } catch (err) {
    return {};
  }
};

export const setSignatureInSession = (value: Signature): void => {
  localStorage.setItem('signature', JSON.stringify(value));
};

export const removeSignatureFromSession = (): void => {
  localStorage.removeItem('signature');
};

export const getValueFromSession = (label: string): any => {
  const value = localStorage.getItem(label);
  try {
    if (value) return JSON.parse(value);
    return '';
  } catch (err) {
    return '';
  }
};

export const setValueInSession = (label: string, value: any): void => {
  localStorage.setItem(label, JSON.stringify(value));
};

export const removeValueFromSession = (label: string): void => {
  localStorage.removeItem(label);
};
