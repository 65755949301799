import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from 'react';

import THEMES, { ThemeType } from 'types/theme';
import { getValueFromSession } from 'utills/storage';

interface ContextType {
  theme: ThemeType;
  themeName: string;
}

const ThemeContext = createContext<ContextType>({
  theme: THEMES.default,
  themeName: 'Default',
});

interface Props {
  children?: ReactNode;
}

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeType>(THEMES.default);
  const [themeName, setThemeName] = useState<string>('Default');
  const branchId = getValueFromSession('branchId');

  useEffect(() => {
    switch (branchId) {
      case '438':
        setTheme(THEMES.atlas);
        setThemeName('Atlas');
        break;
      case '579':
        setTheme(THEMES.unique);
        setThemeName('Unique');
        break;
      case '1161':
        setTheme(THEMES.florida);
        setThemeName('Florida');
        break;
      case '29':
        setTheme(THEMES.conway);
        setThemeName('Conway');
        break;
      case '685':
        setTheme(THEMES.willFix);
        setThemeName('WillFix');
        break;
      case '89':
        setTheme(THEMES.brothers);
        setThemeName('Brothers');
        break;
      case '90':
        setTheme(THEMES.rsandrews);
        setThemeName('RSAndrews');
        break;
      case '804':
        setTheme(THEMES.allGood);
        setThemeName('AllGood');
        break;
      case '803':
        setTheme(THEMES.andys);
        setThemeName('Andys');
        break;
      case '1219':
        setTheme(THEMES.blueDot);
        setThemeName('BlueDot');
        break;
      case '695':
        setTheme(THEMES.ajPerri);
        setThemeName('AjPerri');
        break;
      case '1363':
        setTheme(THEMES.airExperts);
        setThemeName('AirExperts');
        break;
      case '1743':
        setTheme(THEMES.eliteAir);
        setThemeName('EliteAir');
        break;
      case '1341':
        setTheme(THEMES.mcCarthy);
        setThemeName('McCarthy');
        break;
      case '1487':
        setTheme(THEMES.totalComfort);
        setThemeName('TotalComfort');
        break;
      case '1713':
        setTheme(THEMES.aksarben);
        setThemeName('Aksarben');
        break;
      case '84':
        setTheme(THEMES.columbusWorthingtonAir);
        setThemeName('ColumbusWorthingtonAir');
        break;
      case '1712':
        setTheme(THEMES.hauser);
        setThemeName('Hauser');
        break;
      case '1271':
        setTheme(THEMES.comfortLexington);
        setThemeName('ComfortLexington');
        break;
      case '702':
        setTheme(THEMES.bobHamilton);
        setThemeName('BobHamilton');
        break;
      case '1445':
        setTheme(THEMES.roger);
        setThemeName('Roger');
        break;
      case '691':
        setTheme(THEMES.yes);
        setThemeName('Yes');
        break;
      case '600':
        setTheme(THEMES.greenstar);
        setThemeName('GreenStar');
        break;
      case '669':
        setTheme(THEMES.rightTime);
        setThemeName('RightTime');
        break;
      case '1540':
        setTheme(THEMES.blueflame);
        setThemeName('BlueFlame');
        break;
      case '673':
        setTheme(THEMES.beutler);
        setThemeName('Beutler');
        break;
      default:
        setTheme(THEMES.default);
        setThemeName('Default');
    }
  }, [branchId]);

  const contextValue = useMemo(
    () => ({
      theme,
      themeName,
    }),
    [theme]
  );

  return (
    <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export default useTheme;
