import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';

import useTheme from 'hooks/useTheme';
import Styled from 'types/Styled';
import styles from './Button.module.scss';
import { BasicStyleProps } from 'types/theme';

const useStyles = makeStyles({
  primary: ({ theme }: BasicStyleProps) => ({
    background: `${theme.colors.SECONDARY} 0% 0% no-repeat padding-box`,
    color: theme.colors.BG_LIGHT_BLUE,
    '&:hover': {
      background: `${theme.colors.HOVERSECONDARY} 0% 0% no-repeat padding-box`,
    },
  }),
  secondary: ({ theme }: BasicStyleProps) => ({
    color: theme.colors.SECONDARY,
    background: `${theme.colors.WHITE} 0% 0% no-repeat padding-box`,
    border: `2px solid ${theme.colors.SECONDARY}`,

    '&:hover': {
      border: `2px solid ${theme.colors.HOVERSECONDARY}`,
    },
  }),
  tertiary: ({ theme }: BasicStyleProps) => ({
    background: 'transparent',
    color: theme.colors.WHITE,
    border: `2px solid ${theme.colors.WHITE}`,

    '&:hover': {
      opacity: 0.9,
    },
  }),
  text: ({ theme }: BasicStyleProps) => ({
    display: 'inline',
    width: 'auto',
    letterSpacing: '0px',
    color: theme.colors.SECONDARY,
    fontSize: 16,
    background: 'transparent',

    '&:hover': {
      color: theme.colors.HOVERSECONDARY,
    },
  }),

  disabled: ({ theme }: BasicStyleProps) => ({
    cursor: 'not-allowed',
    backgroundColor: theme.colors.DISABLED,
    '&:hover': {
      backgroundColor: theme.colors.DISABLED,
    },
  }),
});

interface ButtonProps extends Styled {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
}

export function Button(props: PropsWithChildren<ButtonProps>): ReactElement {
  const {
    variant = 'primary',
    onClick,
    children,
    className,
    style,
    type,
    disabled = false,
  } = props;
  const { theme } = useTheme();
  const classes = useStyles({ theme });

  return (
    <button
      onClick={onClick}
      className={classNames(
        styles.button,
        variant !== 'text' && styles.block,
        variant === 'primary' && classes.primary,
        variant === 'secondary' && classes.secondary,
        variant === 'tertiary' && classes.tertiary,
        variant === 'text' && classes.text,
        disabled && classes.disabled,
        className
      )}
      style={style}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
