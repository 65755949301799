export interface ColorTypes {
  // ALABASTER: string;
  // BACKGROUND: string;
  BG_LIGHT_BLUE: string;
  // BLACK: string;
  // BLUE: string;
  // CHATHAMS_BLUE: string;
  // DARKBLUE: string;
  // DARKGRAY: string;
  // DEEP_CERULEAN: string;
  DISABLED: string;
  // DISABLED_TEXT: string;
  // DISCOUNTED: string;
  // GALLERY: string;
  // GRANITE_GRAY: string;
  // GRAY: string;
  // GREEN: string;
  // LIGHT_BLACK: string;
  // LIGHTBLUE: string;
  // LIGHTGREEN: string;
  // LIGHTORANGE: string;
  // ORANGE: string;
  // PRESSED: string;
  PRIMARY: string;
  RED: string;
  RED_LIGHT: string;
  SECONDARY: string;
  HOVERSECONDARY: string;
  // SILVER: string;
  // TEXT_BLACK: string;
  // TEXT_COLOR_BLUE: string;
  // TEXT_COLOR_DISABLED: string;
  // TEXT_COLOR_WHITE: string;
  WHITE: string;
  // DIVIDER_GREY: string;
  // LIGHT_YELLOW: string;
  // LIGHT_GREY: string;
  GRADIENT_PRIMARY: string;
  GRADIENT_SECONDARY: string;
}

export const DefaultColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#e9f0f5',
  // BLACK: '#000000',
  // BLUE: '#053F73',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#0B2744',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#B1C6D8',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#007DBA',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#053F73',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#007DBA',
  HOVERSECONDARY: '#018ACE',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#007DBA',
  // TEXT_COLOR_DISABLED: '#B1C6D8',
  // TEXT_COLOR_WHITE: '#E9F0F5',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#053F73',
  GRADIENT_SECONDARY: '#007DBA',
};

export const AtlasColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#EFF8FF',
  // BLACK: '#000000',
  // BLUE: '#1A3D6D',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#03182B',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#007DBB',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#1A3D6D',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#007DBB',
  HOVERSECONDARY: '#018AE0',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#007DBB',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#EFF8FF',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#1A3D6D',
  GRADIENT_SECONDARY: '#007DBB',
};

export const UniqueColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#6E3322',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#AA6600',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#6E3322',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#AA6600',
  HOVERSECONDARY: '#AC7300',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#AA6600',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#6E3322',
  GRADIENT_SECONDARY: '#6E3322',
};

export const FloridaColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#1D4F91',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#003060',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#DE7008',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#1D4F91',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#DE7008',
  HOVERSECONDARY: '#E18208',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#DE7008',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#1D4F91',
  GRADIENT_SECONDARY: '#1D4F91',
};

export const ConwayColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#1A428A',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#1A428A',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#1A428A',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#1A428A',
  HOVERSECONDARY: '#2764D2',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#1A428A',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#1A428A',
  GRADIENT_SECONDARY: '#1A428A',
};

export const WillFixColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#2F3E81',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#2F3E81',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#2F3E81',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#2F3E81',
  HOVERSECONDARY: '#475CBC',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#2F3E81',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#2F3E81',
  GRADIENT_SECONDARY: '#2F3E81',
};

export const BrothersColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#0075BF',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#0075BF',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#0075BF',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#0075BF',
  HOVERSECONDARY: '#0FA2FE',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#0075BF',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#0075BF',
  GRADIENT_SECONDARY: '#0075BF',
};

export const RSAndrewsColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#003595',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#003595',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#003595',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#003595',
  HOVERSECONDARY: '#0055EF',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#003595',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#003595',
  GRADIENT_SECONDARY: '#003595',
};

export const AllGoodColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#374A86',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#374A86',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#374A86',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#374A86',
  HOVERSECONDARY: '#546CB8',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#374A86',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#374A86',
  GRADIENT_SECONDARY: '#374A86',
};

export const AndysColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#003B71',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#003B71',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#003B71',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#003B71',
  HOVERSECONDARY: '#006ED4',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#003B71',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#003B71',
  GRADIENT_SECONDARY: '#003B71',
};

export const BlueDotColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#0054A4',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#009DD6',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#0054A4',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#009DD6',
  HOVERSECONDARY: '#20C3FF',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#009DD6',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#0054A4',
  GRADIENT_SECONDARY: '#009DD6',
};

export const AjPerriColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#0059A3',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#0059A3',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#0059A3',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#0059A3',
  HOVERSECONDARY: '#0088F9',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#0059A3',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#0059A3',
  GRADIENT_SECONDARY: '#0059A3',
};

export const AirExpertColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#004175',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#004175',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#004175',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#004175',
  HOVERSECONDARY: '#0077D7',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#004175',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#004175',
  GRADIENT_SECONDARY: '#004175',
};

export const EliteAirColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#2D3384',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#262222',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#2D3384',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#2D3384',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#2D3384',
  HOVERSECONDARY: '#444DBF',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#2D3384',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#2D3384',
  GRADIENT_SECONDARY: '#222662',
};

export const McCarthyColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#013C2B',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#00553D',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#013C2B',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#00553D',
  HOVERSECONDARY: '#00BF89',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#00553D',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#013C2B',
  GRADIENT_SECONDARY: '#00553D',
};

export const TotalComfortColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#231F20',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#8E2A2A',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#231F20',
  RED: '#8E2A2A',
  RED_LIGHT: '#E02726',
  SECONDARY: '#8E2A2A',
  HOVERSECONDARY: '#C74242',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#8E2A2A',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#231F20',
  GRADIENT_SECONDARY: '#231F20',
};

export const AksarbenColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#0163A7',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#0C223F',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#0163A7',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#0163A7',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#0163A7',
  HOVERSECONDARY: '#0195FB',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#0163A7',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#0163A7',
  GRADIENT_SECONDARY: '#0163A7',
};

export const ColumbusWorthingtonAirColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#221E1F',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#A12743',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#221E1F',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#A12743',
  HOVERSECONDARY: '#D14364',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#A12743',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#221E1F',
  GRADIENT_SECONDARY: '#221E1F',
};

export const HauserColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#093266',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#020000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#009DD6',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#093266',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#009DD6',
  HOVERSECONDARY: '#20C3FF',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#009DD6',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#093266',
  GRADIENT_SECONDARY: '#093266',
};

export const ComfortLexingtonColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#1D4596',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#1D4596',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#1D4596',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#1D4596',
  HOVERSECONDARY: '#2E66D6',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#1D4596',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#1D4596',
  GRADIENT_SECONDARY: '#1D4596',
};

export const BobHamiltonColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#003595',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#D41773',
  // BUTTON_SHADOW: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#003595',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#003595',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#003595',
  HOVERSECONDARY: '#0055EF',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#003595',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#003595',
  GRADIENT_SECONDARY: '#003595',
};

export const RogerColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#333333',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#E9352D',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#333333',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#E9352D',
  HOVERSECONDARY: '#EE6761',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#E9352D',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#333333',
  GRADIENT_SECONDARY: '#333333',
};

export const YesColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#043673',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#001641',
  // BUTTON_SHADOW: '#001641',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#00A39B',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#043673',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#00A39B',
  HOVERSECONDARY: '#00F9ED',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#00A39B',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#043673',
  GRADIENT_SECONDARY: '#043673',
};

export const GreenstarColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#33333B',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // BUTTON_SHADOW: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#00A160',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#33333B',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#00A160',
  HOVERSECONDARY: '#00F894',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#00A160',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#33333B',
  GRADIENT_SECONDARY: '#33333B',
};

export const RightTimeColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#33333B',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // BUTTON_SHADOW: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#38A845',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#33333B',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#38A845',
  HOVERSECONDARY: '#5EC96A',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#38A845',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#33333B',
  GRADIENT_SECONDARY: '#33333B',
};

export const BeutlerColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#002453',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#000000',
  // BUTTON_SHADOW: '#000000',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#002453',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#002453',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#002453',
  HOVERSECONDARY: '#0052BD',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#002453',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#002453',
  GRADIENT_SECONDARY: '#002453',
};

export const BlueflameColors: ColorTypes = {
  // ALABASTER: '#F9F9F9',
  // BACKGROUND: '#3333FF',
  BG_LIGHT_BLUE: '#F7F7F7',
  // BLACK: '#000000',
  // BLUE: '#295989',
  // CHATHAMS_BLUE: '#143C6D',
  // DARKBLUE: '#33669A',
  // BUTTON_SHADOW: '#33669A',
  // DARKGRAY: '#343434',
  // DEEP_CERULEAN: '#0079B6',
  DISABLED: '#CCCCCC',
  // DISABLED_TEXT: '#6E6E6E',
  // DISCOUNTED: '#616161',
  // GALLERY: '#F0F0F0',
  // GRANITE_GRAY: '#666666',
  // GRAY: '#707070',
  // GREEN: '#177B3F',
  // LIGHT_BLACK: '#212121',
  // LIGHTBLUE: '#5D9921',
  // LIGHTGREEN: '#63BB4A',
  // LIGHTORANGE: '#FFBE2C',
  // ORANGE: '#DD7E00',
  // PRESSED: 'rgba(0, 125, 186, 0.2)',
  PRIMARY: '#295989',
  RED: '#942636',
  RED_LIGHT: '#E02726',
  SECONDARY: '#5D9921',
  HOVERSECONDARY: '#85D337',
  // SILVER: '#CCCCCC',
  // TEXT_BLACK: '#000000DE',
  // TEXT_COLOR_BLUE: '#5D9921',
  // TEXT_COLOR_DISABLED: '#CCCCCC',
  // TEXT_COLOR_WHITE: '#F7F7F7',
  WHITE: '#FFFFFF',
  // DIVIDER_GREY: '#EEEEEE',
  // LIGHT_YELLOW: '#FFF5E5',
  // LIGHT_GREY: '#C1C1C1',
  GRADIENT_PRIMARY: '#295989',
  GRADIENT_SECONDARY: '#34CBD9',
};
