import { useNavigate } from 'react-router-dom';

import { usePDFContex } from 'hooks/usePDFContex';
import { Button } from 'components/Button';
import { getValueFromSession } from 'utills/storage';

import HelpCenterLogo from 'components/HelpCenterLogo/HelpCenterLogo';
import Logo from 'components/Logo/Logos';

import styles from './Congratulation.module.scss';

export default function CongratulationPage() {
  const navigate = useNavigate();
  const { pdf } = usePDFContex();
  const branchPhoneNumber = getValueFromSession('branchPhoneNumber');

  const handleDownload = () => {
    if (pdf) {
      const link = document.createElement('a');
      const blob = new Blob([pdf], { type: 'application/pdf' });
      link.href = URL.createObjectURL(blob);
      const serviceOrder = getValueFromSession('serviceOrder');
      link.download = `signed-service-order-${serviceOrder}.pdf`;
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  };

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <h2 className={styles.title}>Congratulations!</h2>
      <p className={styles.subTitle}>
        Your Home Comfort Installer will be in touch to schedule your appointment.
      </p>
      <p className={styles.description}>
        You can download your signed document now or we will send you a link via
        email.
      </p>
      <HelpCenterLogo className={styles.help} />
      <p className={styles.description}>
        <b>Questions?</b> We’re here to help!
      </p>
      <p className={styles.tel}>{branchPhoneNumber || ''}</p>
      <div className={styles.actions}>
        <Button onClick={handleDownload}>DOWNLOAD</Button>
      </div>
    </div>
  );
}
