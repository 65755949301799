import { createContext, useContext, useState } from 'react';
import { PDFDocument } from 'pdf-lib';

interface PDFContextInterface {
  pdf: any;
  setPdf: any;
}

const PDFCtx = createContext<PDFContextInterface>({
  pdf: null,
  setPdf: null,
});

export const PDFContextProvider = ({
  defaulPDF,
  children,
}: {
  defaulPDF: any;
  children: React.ReactNode | React.ReactNodeArray;
}) => {
  // This is the exact same logic that we previously had in our hook
  const [pdf, setPdf] = useState<PDFDocument | null>(defaulPDF);

  return <PDFCtx.Provider value={{ pdf, setPdf }}>{children}</PDFCtx.Provider>;
};

export const usePDFContex = () => {
  const { pdf, setPdf } = useContext(PDFCtx);
  return { pdf, setPdf };
};
