import { PropsWithChildren, ReactElement } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import Styled from 'types/Styled';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as AttentionIcon } from 'assets/icons/attentionmark.svg';

import styles from './PDFAlert.module.scss';

interface PDFAlertProps extends Styled {
  description: string;
  isModalOpen: boolean;
  isError: boolean;
  onShow(): void;
}

Modal.setAppElement('#root');

export const PDFAlert = ({
  isModalOpen,
  isError = false,
  description,
  onShow,
}: PropsWithChildren<PDFAlertProps>): ReactElement => (
  <Modal
    isOpen={isModalOpen}
    shouldCloseOnEsc={false}
    className={classNames(styles.content, !isError && styles.successContent)}
    overlayClassName={styles.overlay}
    contentLabel="Modal"
    shouldCloseOnOverlayClick={false}
  >
    <div className={styles.description}>
      {isError ? <AttentionIcon width={20} /> : <CheckIcon width={20} />}
      <span>{description}</span>
    </div>
    {isError && (
      <div className={styles.errorButton} onClick={onShow}>
        Show Me
      </div>
    )}
  </Modal>
);
